import React, {useState, useEffect} from 'react';
import "../styles/index.scss"

const containerStyles = {
    textAlign: "center",
}

const DAYS = ["niedziela", "poniedziałek", "wtorek", "środa", "czwartek", "piątek", "sobota"]
const MONTHS = [
    "styczeń",
    "luty",
    "marzec",
    "kwiecień",
    "maj",
    "czerwiec",
    "lipiec",
    "sierpień",
    "wrzesień",
    "październik",
    "listopad",
    "grudzień"
]

const IndexPage = () => {
    const [hour, setHour] = useState(null);
    const [minutes, setMinutes] = useState(null);
    const [dayPart, setDayPart] = useState('');
    const [dayPartSymbol, setDayPartSymbol] = useState('');
    const [visibility, setVisibility] = useState('visible');


    const getDay = () => {
        const now = new Date();
        return DAYS[now.getDay()].toUpperCase()
    }

    const getDate = () => {
        const now = new Date();
        return `${now.getUTCDate()} ${MONTHS[now.getMonth()]} ${now.getUTCFullYear()}`
    }

    function tick() {
        const now = new Date();
        const hour = String(now.getHours()).padStart(2, ' ');
        const minutes = String(now.getMinutes()).padStart(2, '0');

        const separator = now.getSeconds() % 2 ? 'hidden' : 'visible';

        setMinutes(minutes);
        setHour(hour);
        setVisibility(separator);

        const hourVal = now.getHours();
        setHour(hourVal);
        if (hourVal <= 3) {
            setDayPart('Noc');
            setDayPartSymbol(String.fromCodePoint(0x1F31C));
        } else if (hourVal <= 5) {
            setDayPart('Nad ranem');
            setDayPartSymbol(String.fromCodePoint(0x1F31C));
        } else if (hourVal <= 9) {
            setDayPart('Rano');
            setDayPartSymbol(String.fromCodePoint(0x1F31E));
        } else if (hourVal <= 11) {
            setDayPart(`Przedpołudnie`);
            setDayPartSymbol(String.fromCodePoint(0x1F31E));
        } else if (hourVal <= 16) {
            setDayPart('Popołudnie');
            setDayPartSymbol(String.fromCodePoint(0x1F31E));
        } else if (hourVal <= 17) {
            setDayPart('Późne Popołudnie');
            setDayPartSymbol(String.fromCodePoint(0x1F31E));
        } else if (hourVal <= 20) {
            setDayPart('Wieczór');
            setDayPartSymbol(String.fromCodePoint(0x1F31E));
        } else if (hourVal <= 22) {
            setDayPart('Późny wieczór');
            setDayPartSymbol(String.fromCodePoint(0x1F31C));
        } else {
            setDayPart('Noc');
            setDayPartSymbol(String.fromCodePoint(0x1F31C));
        }
    }

    useEffect(() => {

        tick();
        let myInterval = setInterval(() => {
            tick()
        }, 1000);


        return function cleanup() {
            clearInterval(myInterval);
        };

    });


    return (
        <main>
            <div className="container" style={containerStyles}>
                <h2 className="day-name">Dzisiaj jest {getDay()}</h2>
                <h2 className="date">{getDate()}</h2>
                <h2 className="hour">{hour}<span style={{visibility}}>:</span>{minutes}</h2>

                <h2 className="day-part">{dayPartSymbol} {dayPart}</h2>

            </div>
        </main>
    )
}

export default IndexPage
